import React, { useCallback } from 'react';
import { translate } from 'utils';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TextField } from 'components/forms/inputs/InputField/InputField';
import { Datepicker } from 'components';
import { ExaminationService } from 'services';
import { useSnackbar } from 'notistack';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';

export const QuizResultsListFilters = ({ currentFilters, setCurrentFilters }) => {
  const { enqueueSnackbar } = useSnackbar();

  const trainee = (currentFilters && currentFilters.find(filt => filt.key === 'trainee')
    && currentFilters.find(filt => filt.key === 'trainee').value) || null;

  const quizName = (currentFilters && currentFilters.find(filt => filt.key === 'quizName')
        && currentFilters.find(filt => filt.key === 'quizName').label) || '';

  const minDate = (currentFilters && currentFilters.find(filt => filt.key === 'minDate')
        && currentFilters.find(filt => filt.key === 'minDate').label) || null;

  const maxDate = (currentFilters && currentFilters.find(filt => filt.key === 'maxDate')
        && currentFilters.find(filt => filt.key === 'maxDate').label) || null;

  const handleChangeInput = useCallback(event => {
    const { value, name } = event.target;
    if (value) {
      if (currentFilters) {
        setCurrentFilters([...currentFilters.filter(filt => filt.key !== name), { key: name, label: value }]);
      } else {
        setCurrentFilters([{ key: name, label: value }]);
      }
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== name)]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleSelect = useCallback(value => {
    if (value) {
      if (currentFilters) {
        setCurrentFilters([...currentFilters.filter(filt => filt.key !== 'trainee'), {
          key: 'trainee',
          label: value.label || value,
          value
        }]);
      } else {
        setCurrentFilters([{ key: 'trainee', label: value.label || value, value }]);
      }
    } else {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== 'trainee')]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleChangeDate = ({ date, key }) => {
    if (currentFilters) {
      setCurrentFilters([...currentFilters.filter(filt => filt.key !== key), { key, label: date }]);
    } else {
      setCurrentFilters([{ key, label: date }]);
    }
  };

  const handleFilterOptions = useCallback(option => trainee?.value !== option.value, [trainee]);

  const getTraineesAsyncOptions = useCallback((inputValue) => new Promise(resolve => {
    ExaminationService.getTraineesList(inputValue || '')
      .then(response => resolve(response))
      .catch(() => enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' }));
  }), [enqueueSnackbar]);

  const debouncedLoadOptions = debounce(getTraineesAsyncOptions, 500);

  return (
    <Grid container direction="column">
      <AsyncSelect
        cacheOptions
        closeMenuOnSelect
        defaultOptions
        filterOption={handleFilterOptions}
        isClearable
        loadingMessage={() => translate('common.loading')}
        loadOptions={inputValue => debouncedLoadOptions(inputValue)}
        name="trainees"
        noOptionsMessage={() => translate('errors.noOptions')}
        placeholder={translate('common.selectIntern')}
        styles={{ menu: base => ({ ...base, zIndex: 2000 }) }}
        value={trainee}
        onChange={handleSelect}
      />
      <TextField
        InputProps={{
          autoComplete: 'off'
        }}
        label={translate('quiz.quizName')}
        name="quizName"
        value={quizName}
        onChange={handleChangeInput}
      />
      <Datepicker
        dataCy="quizResultsMinDate"
        fullWidth
        label="common.minDate"
        name="minDate"
        value={minDate}
        onChange={date => handleChangeDate({ date, key: 'minDate' })}
      />
      <br />
      <Datepicker
        dataCy="quizResultsMaxDate"
        fullWidth
        label="common.maxDate"
        name="maxDate"
        value={maxDate}
        onChange={date => handleChangeDate({ date, key: 'maxDate' })}
      />
    </Grid>
  );
};

QuizResultsListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};
