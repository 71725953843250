import {
  faCheck, faCode, faQuestion, faSave, faTimes
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton, TableCell, Tooltip, Typography
} from '@material-ui/core';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { I18nService } from 'services';
import styled from 'styled-components';
import { translate } from 'utils';

import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { InputField } from 'components/';
import { Button, DropdownButton } from 'components/_commons';
import sanitizeHtml from 'sanitize-html';

import { i18nStore } from 'stores';
import ValidationSwitchTriple from './ValidationSwitchTriple';

const StyledInputTextWrapper = styled.div`
  position: relative !important;
  
  --border: ${props => props.border};
  
  &::before {
    content: '';
    position: absolute;
    top: ${props => props.top ?? '0'};
    bottom: ${props => props.bottom ?? '0'};
    left: 0;
    border-left: var(--border);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

const StyledSimpleText = styled.div`
  max-width: 100%;
  min-width: 0;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const StyledHTMLField = styled.div`
  padding: 8px 14px;
  border: 1px solid var(--grey-light);
  border-radius: 4px;
  background-color: var(--grey-lighter);
  word-wrap: break-word;

  p, ol, ul {margin: 0};
`;

export const TRANSLATION_STATUS = {
  TO_TRANSLATE: 'TO_TRANSLATE',
  TO_VALIDATE: 'TO_VALIDATE',
  ACCEPTED: 'ACCEPTED',
  REFUSED: 'REFUSED'
};

const getSavedTarget = row => {
  if (!row.targetStatus) return row.targetLabel ?? '';
  return row.suggestedTargetLabel ?? row.targetLabel ?? '';
};

const getDisplayedTarget = row => {
  if (row.modifiedTargetLabel !== undefined) return row.modifiedTargetLabel;
  return getSavedTarget(row);
};

const getBorderColor = row => {
  const isToTranslate = row.targetStatus === TRANSLATION_STATUS.TO_TRANSLATE;
  const isToValidate = row.targetStatus === TRANSLATION_STATUS.TO_VALIDATE;
  const isRefused = row.targetStatus === TRANSLATION_STATUS.REFUSED;
  const border = '6px solid';
  const isSuggested = Boolean(row.suggestedTargetLabel);
  const emptyOutOfCampaign = !(row.targetStatus || row.targetLabel);
  if ((isSuggested && isToTranslate) || isToValidate) {
    return `${border} var(--warning-color)`;
  }
  if (isToTranslate || isRefused || emptyOutOfCampaign) {
    return `${border} var(--error-color)`;
  }
  return `${border} var(--success-color)`;
};

export const TargetTemplate = ({
  row, isUserValidator, isUserTranslator, handleChangeLabel
}) => {
  const displayModal = useModal();
  const [targetLabel, setTargetLabel] = useState(getDisplayedTarget(row));
  const [edited, setEdited] = useState(Boolean(row.modifiedTargetLabel));
  const [isVariableAmountCorrect, setIsVariableAmountCorrect] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const isToTranslate = row.targetStatus === TRANSLATION_STATUS.TO_TRANSLATE;
  const borderColor = getBorderColor(row);
  const variableAmount = text => (text?.match(/{.*?}/g) || []).length;
  const inCampaign = Boolean(row.targetStatus);

  useEffect(() => {
    setIsVariableAmountCorrect(variableAmount(targetLabel) === variableAmount(row.sourceLabel));
  }, [targetLabel, row.sourceLabel]);

  const handleChange = useCallback((value) => {
    if (/<p>.*?<\/p>$/i.test(value)) {
      // eslint-disable-next-line no-param-reassign
      value = value.substring(3, value.length - 4);
    }
    setEdited(value !== row.targetLabel);
    setTargetLabel(value);
    handleChangeLabel({
      newLabel: value,
      oldLabel: getSavedTarget(row),
      id: row.targetId,
      inCampaign
    });
  }, [handleChangeLabel, inCampaign, row]);

  const handleSaveModify = () => {
    const params = {};
    params[row.targetId] = targetLabel;
    I18nService.modifyTranslations(params).then(() => {
      // eslint-disable-next-line no-param-reassign
      row.targetLabel = targetLabel;
      handleChangeLabel({ id: row.targetId, inCampaign });
      setEdited(false);
      enqueueSnackbar(translate('pageTranslationList.snackbar.oneSaved'), { variant: 'success' });
      i18nStore.loadLanguage(localStorage.getItem('i18nextLng'));
    }).catch(error => enqueueSnackbar(error, { variant: 'error' }));
  };

  const displayHtmlTargetInput = useCallback(() => (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Tooltip title={translate('pageTranslationList.editHtml.tooltip')}>
          <span>
            <Button
              color="primary"
              startIcon={<FontAwesomeIcon icon={faCode} />}
              onClick={() => displayModal({
                type: 'EDIT_HTML',
                defaultValue: targetLabel,
                text: 'pageTranslationList.editHtml.title',
                onConfirm: handleChange
              })}
            >
              {translate('button.editTranslation')}
            </Button>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <StyledInputTextWrapper border={borderColor} className="textWrapper">
          <StyledHTMLField dangerouslySetInnerHTML={{ __html: sanitizeHtml(targetLabel) }} />
        </StyledInputTextWrapper>
      </Grid>
    </Grid>
  ), [borderColor, displayModal, handleChange, targetLabel]);

  const displaySimpleHtml = useCallback(() => (
    <StyledHTMLField dangerouslySetInnerHTML={{ __html: sanitizeHtml(targetLabel) }} />
  ), [targetLabel]);

  const displayTargetInput = useCallback(() => (
    <>
      <StyledInputTextWrapper border={borderColor} bottom="4px" className="textWrapper" top="8px">
        <InputField
            multiline
            name={`target_${row.targetId}`}
            required
            value={targetLabel}
            onChange={(_, value) => handleChange(value)}
          />
      </StyledInputTextWrapper>
      {!isVariableAmountCorrect && (
        <Typography style={{ marginLeft: '15px', fontSize: 'small', color: 'var(--warning-color)' }}>
          {translate('pageTranslationList.incorrectVariableAmountTooltip', { amount: variableAmount(row.sourceLabel) })}
        </Typography>
      )}
    </>
  ), [borderColor, handleChange, isVariableAmountCorrect, row, targetLabel]);

  const displaySimpleLabel = useCallback(() => (
    <div style={{ margin: '10px 0' }}>
      {targetLabel}
    </div>
  ), [targetLabel]);

  const getTargetDisplay: () => any = useCallback(() => {
    if (isToTranslate && isUserTranslator) {
      return (row.htmlFormat) ? displayHtmlTargetInput() : displayTargetInput();
    }
    if (isToTranslate && isUserValidator) {
      return (row.htmlFormat) ? displaySimpleHtml() : displaySimpleLabel();
    }
    if (isUserValidator) {
      return (row.htmlFormat) ? displayHtmlTargetInput() : displayTargetInput();
    }
    return (row.htmlFormat) ? displaySimpleHtml() : displaySimpleLabel();
  }, [row.htmlFormat, displayTargetInput, displaySimpleLabel, displayHtmlTargetInput,
    displaySimpleHtml, isToTranslate, isUserTranslator, isUserValidator]);

  return (
    <div data-id={row.targetId} data-targetlabel={targetLabel}>
      <Grid alignItems="center" container direction="row" spacing={1}>
        <Grid item xs>
          {getTargetDisplay()}
        </Grid>
        {edited && !inCampaign && (
          <Grid item>
            <Tooltip title={translate('button.save')}>
              <IconButton color="primary" onClick={handleSaveModify}>
                <FontAwesomeIcon fixedWidth icon={faSave} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const getTripleSwitchValue = row => {
  if (row.targetStatus === TRANSLATION_STATUS.ACCEPTED) return 'validated';
  if (row.targetStatus === TRANSLATION_STATUS.REFUSED) return 'refused';
  return 'unset';
};

export const ValidateTemplate = ({ row }) => {
  const isToValidate = row.targetStatus === TRANSLATION_STATUS.TO_VALIDATE;
  const isAccepted = row.targetStatus === TRANSLATION_STATUS.ACCEPTED;
  const isRefused = row.targetStatus === TRANSLATION_STATUS.REFUSED;
  const [value, setValue] = useState(getTripleSwitchValue(row));

  useEffect(() => {
    setValue(getTripleSwitchValue(row));
    const element = document.querySelector(`[data-id="${row.targetId}"]`);
    const textWrapper: any = element.querySelector('.textWrapper');
    textWrapper?.style.removeProperty('--border');
  }, [row]);

  const handleToggleChange = v => {
    setValue(v);
    if (v === 'refused') {
      // eslint-disable-next-line no-param-reassign
      row.targetStatus = TRANSLATION_STATUS.REFUSED;
      I18nService.refuseTranslations([row.targetId]);
    } else if (v === 'validated') {
      // eslint-disable-next-line no-param-reassign
      row.targetStatus = TRANSLATION_STATUS.ACCEPTED;
      I18nService.acceptTranslations([row.targetId]);
    }
    const element = document.querySelector(`[data-id="${row.targetId}"]`);
    const textWrapper: any = element.querySelector('.textWrapper');
    textWrapper.style.setProperty('--border', getBorderColor(row));
  };

  return (isToValidate || isRefused || isAccepted)
    ? (
      <div style={{ margin: '0 25px' }}>
        <ValidationSwitchTriple name="acceptToggle" value={value} onChange={handleToggleChange} />
      </div>
    ) : (<></>);
};

export const GlobalToggleActions = ({ onAcceptAll, onRefuseAll, onResetAll }) => (
  <DropdownButton
    buttonStyle={{ padding: 6 }}
    color="primary"
    icon={faEllipsisV}
    iconSize="xs"
    isIconOnly
    placement="bottom"
    popperMargin="6px"
  >
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Button
          edge="end"
          startIcon={<FontAwesomeIcon icon={faQuestion} />}
          type="secondary"
          onClick={onResetAll}
        >
          {translate('button.resetAll')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          edge="end"
          startIcon={<FontAwesomeIcon icon={faCheck} />}
          type="secondary"
          onClick={onAcceptAll}
        >
          {translate('button.validateAll')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          edge="end"
          startIcon={<FontAwesomeIcon icon={faTimes} />}
          type="secondary"
          onClick={onRefuseAll}
        >
          {translate('button.refuseAll')}
        </Button>
      </Grid>
    </Grid>
  </DropdownButton>
);

const validatorHeader = runToggleAction => (
  {
    name: 'validate',
    label: translate('pageTranslationList.column.validate'),
    headerRight: (<GlobalToggleActions
      onAcceptAll={() => runToggleAction(I18nService.acceptTranslationsByFilter)}
      onRefuseAll={() => runToggleAction(I18nService.refuseTranslationsByFilter)}
      onResetAll={() => runToggleAction(I18nService.resetToValidateTranslationsByFilter)}
    />),
    template: row => (
      <TableCell key={`${row.targetId}_validate`}>
        <ValidateTemplate row={row} />
      </TableCell>
    )
  }
);

export const listHeaders = ({
  handleChangeLabel, runToggleAction, isUserValidator, isUserTranslator
}) => {
  const firstHeaders = [
    {
      name: 'key',
      label: translate('pageTranslationList.column.key'),
      width: runToggleAction ? '20%' : '25%',
      template: row => (
        <TableCell key={`${row.targetId}_key`}>
          <Tooltip title={row.key}>
            <Typography style={{ wordBreak: 'break-all' }}>
              {row.key ?? '-'}
            </Typography>
          </Tooltip>
        </TableCell>
      )
    },
    {
      name: 'source',
      label: translate('pageTranslationList.column.source'),
      width: '30%',
      template: row => (
        <TableCell key={`${row.targetId}_source`}>
          {row.htmlFormat
              ? <StyledHTMLField dangerouslySetInnerHTML={{ __html: sanitizeHtml(row.sourceLabel) }} />
              : <StyledSimpleText>{row.sourceLabel ?? '-'}</StyledSimpleText>}
        </TableCell>
        )
    }, {
      name: 'target',
      label: translate('pageTranslationList.column.target'),
      width: runToggleAction ? '35%' : '45%',
      template: row => (
        <TableCell key={`${row.targetId}_target`}>
          <TargetTemplate
            handleChangeLabel={handleChangeLabel}
            isUserTranslator={isUserTranslator}
            isUserValidator={isUserValidator}
            row={row}
          />
        </TableCell>
      )
    }
  ];
  return runToggleAction ? [...firstHeaders, validatorHeader(runToggleAction)] : firstHeaders;
};
