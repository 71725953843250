import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Button, DropdownButton } from 'components/_commons';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { CompanyService, InstitutionGroupService, InstitutionService } from 'services';
import shortid from 'shortid';
import styled from 'styled-components';
import { DocumentHelper, translate } from 'utils';
import { STRUCTURE_LEVEL_ICON, STRUCTURE_TYPE } from 'utils/constants';

const DeleteButton = styled(IconButton)`
  &:hover {
    color: var(--error-color);
  }
`;

const TreeNodeButtons = ({
  node, path, activity, setLoading, onCreate, onUpdate, onDelete, onTreeUpdated, onParentCompanyChanged, companyId
}) => {
  const displayModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const onError = useCallback(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }), [enqueueSnackbar]);
  const nodeInstitutionGroupId = node.type === STRUCTURE_TYPE.INSTITUTION_GROUP ? node.value : undefined;
  const nodeCompanyId = node.type === STRUCTURE_TYPE.COMPANY ? node.value : companyId;

  const showDetailModal = props => displayModal({ ...props, closeOnSubmit: true, key: shortid.generate() });

  const handleEditNode = modalProps => {
    const loadBeforeModal = () => {
      setLoading(true);
      modalProps.getDetails(node.value)
        .then(result => showDetailModal({ ...modalProps, defaultValues: result }))
        .catch(onError)
        .finally(onTreeUpdated);
    };
    return node.value ? loadBeforeModal() : showDetailModal({ ...modalProps, defaultValues: {} });
  };

  const handleDeleteNode = () => {
    const switchNodeDeleteFunc = {
      INSTITUTION_MODEL: () => InstitutionService.deleteInstitution(node.value),
      COMPANY: () => CompanyService.deleteCompany(node.value),
      INSTITUTION_GROUP: () => InstitutionGroupService.deleteInstitutionGroup(node.value)
    };
    setLoading(true);
    displayModal({
      type: 'WARNING',
      title: translate('warnings.structureLevel.delete.title'),
      text: translate('warnings.structureLevel.delete.text'),
      buttonConfirm: translate('button.confirm'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => switchNodeDeleteFunc[node.type]()
        .then(() => onDelete({ node }))
        .catch(error => {
          onError(error);
          setLoading(false);
        }),
      onCancel: () => setLoading(false)
    });
  };

  const handleCreateNode = useCallback(({ serviceFunc, confirmKey, isNew }) => {
    setLoading(true);
    return serviceFunc()
      .then(newNode => {
        const confirmLabel = translate(`confirms.${confirmKey}.${isNew ? 'create' : 'update'}`);
        isNew
          ? onCreate({
            confirmLabel, newNode, parentNode: node, parentPath: path
          })
          : onUpdate({ confirmLabel, newNode, path });
      })
      .catch(onError)
      .finally(onTreeUpdated);
  }, [node, onCreate, onUpdate, onTreeUpdated, onError, path, setLoading]);

  const createCompanyFunc = useCallback(result => (result.id
    ? CompanyService.updateCompany(result.id, { ...result, parentCompanyId: nodeCompanyId })
      .then(() => handleCreateNode({
        serviceFunc: () => CompanyService.getCompanyTree(result.id, activity.value),
        confirmKey: 'companyList',
        isNew: !result.id
      })).catch(onError)
    : CompanyService.createCompany({ ...result, parentCompanyId: nodeCompanyId })
      .then(resp => handleCreateNode({
        serviceFunc: () => CompanyService.getCompanyTree(resp, activity.value),
        confirmKey: 'companyList',
        isNew: !result.id
      }))), [handleCreateNode, onError, activity.value, nodeCompanyId]);

  const createParentCompanyFunc = useCallback(result => (
    (result.id
      ? CompanyService.updateCompany(result.id, result)
      : CompanyService.createCompany({ ...result, childCompanies: [nodeCompanyId] }))
      .then(onParentCompanyChanged)
      .catch(onError)
  ), [onParentCompanyChanged, onError, nodeCompanyId]);

  const createInstitutionGroupFunc = useCallback(result => {
    const serviceFunc = () => (result.id
      ? InstitutionGroupService.updateInstitutionGroup(result.id, result, activity.value)
      : InstitutionGroupService.createInstitutionGroup(result, activity.value));
    return handleCreateNode({
      serviceFunc, confirmKey: 'institutionGroup', isNew: !result.id
    });
  }, [handleCreateNode, activity.value]);

  const createInstitutionFunc = useCallback(result => {
    const formattedResult = { ...result, documents: result.documents?.map(doc => DocumentHelper.getDocumentWithoutBase64(doc)) };
    return (formattedResult.id
      ? InstitutionService.updateInstitution(formattedResult.id, formattedResult)
        .then(() => handleCreateNode({
          serviceFunc: () => InstitutionService.getInstitutionTree(formattedResult.id, activity.value),
          confirmKey: 'institutionList',
          isNew: !formattedResult.id
        }))
        .catch(onError)
      : InstitutionService.createInstitution(formattedResult)
        .then(resp => handleCreateNode({
          serviceFunc: () => InstitutionService.getInstitutionTree(resp, activity.value),
          confirmKey: 'institutionList',
          isNew: !formattedResult.id
        })).catch(onError));
  }, [handleCreateNode, onError, activity.value]);

  const nodeModalParams = {
    INSTITUTION_MODEL: {
      type: 'CREATE_INSTITUTION',
      getDetails: InstitutionService.getInstitution,
      onConfirm: createInstitutionFunc,
      parentInstitutionGroupId: nodeInstitutionGroupId,
      parentCompanyId: nodeCompanyId
    },
    COMPANY: {
      type: 'CREATE_COMPANY',
      getDetails: CompanyService.getCompany,
      onConfirm: createCompanyFunc,
      parentCompanyId: nodeCompanyId
    },
    PARENT_COMPANY: {
      type: 'CREATE_COMPANY',
      getDetails: CompanyService.getCompany,
      onConfirm: createParentCompanyFunc
    },
    INSTITUTION_GROUP: {
      type: 'CREATE_INSTITUTION_GROUP',
      getDetails: InstitutionGroupService.getInstitutionGroup,
      onConfirm: createInstitutionGroupFunc,
      parentInstitutionGroupId: nodeInstitutionGroupId,
      parentCompanyId: nodeCompanyId,
      defaultActivity: activity
    }
  };

  const getItemButton = (type, label) => (
    <Grid item>
      <Button
        edge="end"
        startIcon={<FontAwesomeIcon fixedWidth icon={STRUCTURE_LEVEL_ICON[type]} />}
        type="secondary"
        onClick={() => showDetailModal(nodeModalParams[type])}
      >
        {label}
      </Button>
    </Grid>
  );

  return node.canEdit && (
    <div style={{ marginLeft: '10px' }}>
      <Tooltip title={translate('button.viewEdit')}>
        <span>
          <IconButton
            color="primary"
            style={{ padding: 5 }}
            onClick={() => handleEditNode(nodeModalParams[node.type])}
          >
            <FontAwesomeIcon fixedWidth icon={faEdit} size="xs" />
          </IconButton>
        </span>
      </Tooltip>
      {(Boolean(node.createChildOptions?.length) || node.canCreateParentCompany) && (
        <DropdownButton
          buttonStyle={{ padding: 5 }}
          color="primary"
          icon={faPlus}
          iconSize="xs"
          isIconOnly
          placement="bottom"
          popperMargin="6px"
          tooltip={translate('common.create')}
        >
          <Grid container direction="column" spacing={2}>
            {node.canCreateParentCompany && getItemButton(STRUCTURE_TYPE.PARENT_COMPANY, translate('common.parentCompany'))}
            {node.createChildOptions.includes(STRUCTURE_TYPE.COMPANY)
                && getItemButton(STRUCTURE_TYPE.COMPANY, translate('common.company'))}
            {node.createChildOptions.includes(STRUCTURE_TYPE.INSTITUTION_GROUP)
                && getItemButton(STRUCTURE_TYPE.INSTITUTION_GROUP, translate('common.institutionGroup'))}
            {node.createChildOptions.includes(STRUCTURE_TYPE.INSTITUTION_MODEL)
                && getItemButton(STRUCTURE_TYPE.INSTITUTION_MODEL, translate('common.institution'))}
          </Grid>
        </DropdownButton>
      )}
      {(node.type !== STRUCTURE_TYPE.COMPANY && node.type !== STRUCTURE_TYPE.PARENT_COMPANY) && (
        <Tooltip title={translate(node.canDelete ? 'button.delete' : 'institutionGroupTree.tree.tooltip.cannotRemove')}>
          <span>
            <DeleteButton
              color="primary"
              disabled={!node.canDelete}
              style={{ padding: 5 }}
              onClick={handleDeleteNode}
            >
              <FontAwesomeIcon fixedWidth icon={faTrashAlt} size="xs" />
            </DeleteButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default TreeNodeButtons;
